.testly-pagination {
  display: inline-flex;
  list-style: none;
  margin-left: 0;
  padding: 0;
  box-sizing: border-box;

  background-color: #ffffff;

  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0.6px;
  color: #a7a9ae;

  fill: #a7a9ae;
}

.testly-pagination li {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 50px;

  box-sizing: border-box;
}

.testly-pagination .disabled {
  fill: #ecedee;
}

.testly-pagination__previous {
  transform: rotate(-180deg);
}

.testly-pagination__next, .testly-pagination__previous, .testly-pagination__page {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  cursor: pointer;

  height: 100%;
  min-width: 50px;
  padding: 0 18px;
  outline: none;
}


.testly-pagination li:nth-last-child(2) {
  border-right: solid 1px #f0f1f1;
}

.testly-pagination li.active {
  background-color: #00ac69;
  color: #ffffff;
  border: none;
}

.testly-pagination li.active + li{
  border-left: none;
}
.testly-pagination li.testly-pagination__break + li{
  border-left: none;
}

.testly-pagination__page-container {
  border-left: solid 1px #f0f1f1;
}
